/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Switch,
  Route,
  BrowserRouter,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { PrivateRoute } from './utils/PrivateRoute';
import { useInjectReducer, useInjectSaga } from './utils/redux-injectors';

// moment js
import * as moment from 'moment';
import 'moment/locale/uk';
import 'moment-duration-format';

// react toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GlobalStyle } from 'styles/global-styles';

// main pages
import { HomePage } from './containers/HomePage/Loadable';
import { SignUpPage } from './containers/SignUpPage/Loadable';
import { NotFoundPage } from './components/layout/NotFoundPage/Loadable';
import { Mission5028 } from 'containers/Mission5028/Loadable';
import { NewsPage } from './containers/NewsPage/Loadable';
import { NewsSinglePage } from './containers/NewsSinglePage/Loadable';
import { StandardsPage } from './containers/StandardsPage/Loadable';
import { StandardsSinglePage } from './containers/StandardsSinglePage/Loadable';
import { PracticalExperiencePage } from './containers/PracticalExperiencePage/Loadable';
import { PracticalExperienceSinglePage } from './containers/PracticalExperienceSinglePage/Loadable';
import { VideoLibraryPage } from './containers/VideoLibraryPage/Loadable';
import { VideoLibrarySinglePage } from './containers/VideoLibrarySinglePage/Loadable';
import { CalendarPage } from './containers/CalendarPage/Loadable';
import { AboutProjectPage } from './containers/AboutProjectPage/Loadable';
import { BroadcastingLibraryPage } from './containers/BroadcastingLibraryPage/Loadable';
import { BroadcastingSinglePage } from './containers/BroadcastingSinglePage/Loadable';
import { CalculatorsPage } from './containers/CalculatorsPage/Loadable';
import { DoctorAndVisitorPage } from './containers/DoctorAndVisitorPage/Loadable';
import { DoctorAndVisitorSinglePage } from './containers/DoctorAndVisitorSinglePage/Loadable';
import { DoctorAndVisitorVideoSinglePage } from './containers/DoctorAndVisitorVideoSinglePage/Loadable';
import { ClinicalCaseSinglePage } from './containers/ClinicalCaseSinglePage/Loadable';
import { ClinicalCasePage } from './containers/ClinicalCasePage/Loadable';
import { UsefulLinksPage } from './containers/UsefulLinksPage/Loadable';
import { EventsCalendarPage } from './containers/EventsCalendarPage/Loadable';
import { PrivacyPolicyPage } from 'containers/PrivacyPolicyPage/Loadable';
import { CookiePolicyPage } from 'containers/CookiePolicyPage/Loadable';

// profile
import { ProfileLayout } from './containers/ProfileLayout/Loadable';

// auth pages
import { LoginPage } from './containers/LoginPage/Loadable';
import { ResetPasswordByEmailPage } from './containers/ResetPassword/ResetPasswordByEmailPage/Loadable';
import { ResetPasswordByPhonePage } from './containers/ResetPassword/ResetPasswordByPhonePage/Loadable';
import { VerificationRestorePasswordBySmsPage } from './containers/VerificationBySmsCode/VerificationRestorePasswordBySmsPage/Loadable';
import { RestorePasswordPage } from './containers/RestorePasswordPage/Loadable';
import { ThanksForRegister } from './containers/ThanksForRegister/Loadable';
import { UserAlreadyConfirmed } from './containers/UserAlreadyConfirmed/Loadable';
import { UnauthorizedPage } from './containers/UnauthorizedPage/Loadable';

// layout
import { AppLayout } from './layouts/AppLayout';
import ModalLayout from './layouts/ModalLayout';

import { Header } from './components/layout/Header';
import { Footer } from './components/layout/Footer';

import { CHA2DS2VAScCalculatorPopup } from './components/features/Calculators/CalculatorsPopups/CHA2DS2VASc/Loadable';
import { CKDCalculatorPopup } from './components/features/Calculators/CalculatorsPopups/CKD/Loadable';
import { CockcroftGaultCalculatorPopup } from './components/features/Calculators/CalculatorsPopups/CockcroftGault/Loadable';
import { DAPTSCALECalculatorPopup } from './components/features/Calculators/CalculatorsPopups/DAPTSCALE/Loadable';
import { GOODWILLCalculatorPopup } from './components/features/Calculators/CalculatorsPopups/GOODWILL/Loadable';
import { GRACECalculatorPopup } from './components/features/Calculators/CalculatorsPopups/GRACE/Loadable';
import { HASBLEDCalculatorPopup } from './components/features/Calculators/CalculatorsPopups/HASBLED/Loadable';
import { IMTCalculatorPopup } from './components/features/Calculators/CalculatorsPopups/IMT/Loadable';
import { MIOCARDCalculatorPopup } from './components/features/Calculators/CalculatorsPopups/MIOCARD/Loadable';
import { PADUACalculatorPopup } from './components/features/Calculators/CalculatorsPopups/PADUA/Loadable';
import { PESICalculatorPopup } from './components/features/Calculators/CalculatorsPopups/PESI/Loadable';
import { SCORECalculatorPopup } from './components/features/Calculators/CalculatorsPopups/SCORE/Loadable';

// locales
import { useTranslation } from 'react-i18next';

// store
import {
  sliceKey as appSliceKey,
  reducer as appReducer,
  actions,
} from './store/app/slice';

import { appSaga } from './store/app/saga';

import { useSelector, useDispatch } from 'react-redux';

// axios
import { $apiClient } from 'utils/request';

// hoc
import withErrorHandler from 'hoc/withErrorHandler';

// utils
import ScrollToTop from './utils/scrollToTop';
import { AuthPage } from './components/layout/AuthPage';
import { selectIsAuthenticated } from './store/app/selectors';
import { Monitoring } from './containers/Monitoring';

// import tag manager
import TagManager from 'react-gtm-module';
import { selectProfile } from './store/profile/selectors';

// toast modal configuration
toast.configure({
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  draggable: true,
  pauseOnHover: true,
});

export function App() {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
  };

  const isLoggedIn = useSelector(selectIsAuthenticated);

  let gtmHasInited = false;

  // inject reducer && saga
  useInjectReducer({ key: appSliceKey, reducer: appReducer });
  useInjectSaga({ key: appSliceKey, saga: appSaga });

  // dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.checkAuthStatusTrigger());
  }, [dispatch]);

  const { i18n } = useTranslation();

  useEffect(() => {
    moment.locale('uk');
  }, []);

  const currentUrl = process.env.REACT_APP_BASE_MAIN_URL?.slice(0, -1);

  const PageLayout = () => (
    <>
      <Helmet
        titleTemplate="%s | Кардiопростiр вiд Servier"
        defaultTitle="Servier"
        htmlAttributes={{ lang: 'uk' }}
        onChangeClientState={(newState, addedTags, removedTags) => {
          if (newState?.title !== 'Servier' && !gtmHasInited && !isLoggedIn) {
            TagManager.initialize(tagManagerArgs);
            gtmHasInited = true;
          }
        }}
      >
        <link
          rel="alternate"
          hrefLang="uk"
          href={currentUrl + window.location.pathname}
        />
        <meta name="description" content="Servier" />
      </Helmet>

      <ScrollToTop />

      <Header />

      <AppSwitch />

      <Footer />
      <GlobalStyle />
    </>
  );

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/cross_auth" component={AuthPage} />
        <Route path={'/monitoring'} component={Monitoring} />
        <Route path={'/mission5028'} component={Mission5028} />
        <Route component={PageLayout} />
      </Switch>
    </BrowserRouter>
  );
}

function AppSwitch() {
  const location = useLocation();

  // dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      location.pathname !== '/login' &&
      location.pathname !== '/thanks-for-register' &&
      location.pathname !== '/user-already-confirmed' &&
      location.pathname !== '/restore-password' &&
      location.pathname !== '/profile'
    )
      dispatch(actions.setLocation(location.pathname));
  }, []);

  // handle gtm register and login events START
  const profileInfo = useSelector(selectProfile);
  const isLoggedIn = useSelector(selectIsAuthenticated);

  console.log(profileInfo);

  function timeoutPromise(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    (async () => {
      await timeoutPromise(500);

      if (
        localStorage.getItem('register_event') == 'true' &&
        isLoggedIn &&
        profileInfo?.data?.id
      ) {
        const tagManagerArgs = {
          gtmId: process.env.REACT_APP_GTM_ID,
          dataLayer: {
            event: 'sign_up',
            uid: profileInfo?.data?.id, //User ID. Id зарегистрированного пользователя.
          },
        };
        TagManager.initialize(tagManagerArgs);
        await localStorage.removeItem('register_event');
      }
    })();

    (async () => {
      await timeoutPromise(500);

      if (
        localStorage.getItem('login_event') == 'true' &&
        isLoggedIn &&
        profileInfo?.data?.id
      ) {
        const tagManagerArgs = {
          gtmId: process.env.REACT_APP_GTM_ID,
          dataLayer: {
            event: 'login',
            uid: profileInfo?.data?.id, //User ID. Id зарегистрированного пользователя.
          },
        };
        TagManager.initialize(tagManagerArgs);
        await localStorage.removeItem('login_event');
      }
    })();
  }, [
    profileInfo?.data?.id,
    isLoggedIn,
    localStorage.getItem('login_event'),
    localStorage.getItem('register_event'),
  ]);
  // handle gtm register and login events END

  const [success, setSuccess] = useState(false);

  const closeModalWindow = value => {
    setSuccess(value);
  };

  // selector
  const [userLoggedIn, setUserLoggedIn] = React.useState(false);

  // @ts-ignore
  const background = location.state && location.state.background;

  useEffect(() => {
    if (isLoggedIn) setUserLoggedIn(true);
  }, []);

  return (
    <AppLayout>
      <Switch location={background || location}>
        {/*<Route exact path={'/restore-password'} render={RestorePasswordPage} />*/}

        <Route
          path={'/thanks-for-register'}
          render={() => (
            <ModalLayout>
              <ThanksForRegister />
            </ModalLayout>
          )}
        />
        <Route
          path={'/user-already-confirmed'}
          render={() => (
            <ModalLayout>
              <UserAlreadyConfirmed />
            </ModalLayout>
          )}
        />

        <Route
          path={'/unauthorised'}
          render={() => (
            <ModalLayout isUnauthorised>
              <UnauthorizedPage />
            </ModalLayout>
          )}
        />

        <Route exact path="/" component={HomePage} />

        <Route exact path="/main/:slug" component={NewsSinglePage} />

        <Route
          exact
          path="/registration"
          render={() => (
            <SignUpPage closePopupMainComponent={closeModalWindow} />
          )}
        />

        <Route exact path={['/education', '/news']} component={NewsPage} />
        <PrivateRoute
          exact
          resourceType={'news'}
          path="/news/:slug"
          component={NewsSinglePage}
        />

        <Route exact path="/about-project" component={AboutProjectPage} />

        <Route exact path="/standarty-likuvannya" component={StandardsPage} />
        <Route
          exact
          path="/standarty-likuvannya/:slug"
          component={StandardsSinglePage}
        />

        <Redirect exact from="/standarts" to="/standarty-likuvannya" />
        <Redirect exact from="/standards" to="/standarty-likuvannya" />
        <Redirect exact from="/useful-links" to="/korisni-posilannya" />

        <Route exact path="/clinical-cases" component={ClinicalCasePage} />

        <PrivateRoute
          exact
          path="/clinical-cases/:slug"
          component={ClinicalCaseSinglePage}
        />

        <Route
          exact
          path="/practical-experience"
          component={PracticalExperiencePage}
        />
        <Route
          exact
          path="/practical-experience/:slug"
          component={PracticalExperienceSinglePage}
        />

        <Route exact path="/video-library" component={VideoLibraryPage} />
        <Route
          exact
          path="/video-library/:slug"
          component={VideoLibrarySinglePage}
        />

        <Route exact path="/calendar" component={CalendarPage} />

        <Route exact path="/webinars" component={BroadcastingLibraryPage} />

        <PrivateRoute
          exact
          resourceType={'webinar'}
          path="/webinars/:slug"
          component={BroadcastingSinglePage}
        />

        {/*<Route*/}
        {/*  exact*/}
        {/*  path="/applications-and-calculators/digital-solutions"*/}
        {/*  component={DigitalSolutionsPage}*/}
        {/*/>*/}
        <Route
          exact
          path="/applications-and-calculators"
          component={CalculatorsPage}
        />
        <Route
          path={'/applications-and-calculators/calculators/cha'}
          component={CHA2DS2VAScCalculatorPopup}
        />
        <Route
          path={'/applications-and-calculators/calculators/skf'}
          component={CKDCalculatorPopup}
        />
        <Route
          path={'/applications-and-calculators/calculators/cockcroft-gault'}
          component={CockcroftGaultCalculatorPopup}
        />
        <Route
          path={'/applications-and-calculators/calculators/dapt-scale'}
          component={DAPTSCALECalculatorPopup}
        />
        <Route
          path={'/applications-and-calculators/calculators/goodwill'}
          component={GOODWILLCalculatorPopup}
        />
        <Route
          path={'/applications-and-calculators/calculators/grace'}
          component={GRACECalculatorPopup}
        />
        <Route
          path={'/applications-and-calculators/calculators/has-bled'}
          component={HASBLEDCalculatorPopup}
        />
        <Route
          path={'/applications-and-calculators/calculators/imt'}
          component={IMTCalculatorPopup}
        />
        <Route
          path={'/applications-and-calculators/calculators/miocard'}
          component={MIOCARDCalculatorPopup}
        />
        <Route
          path={'/applications-and-calculators/calculators/padua-scale'}
          component={PADUACalculatorPopup}
        />
        <Route
          path={'/applications-and-calculators/calculators/pesi'}
          component={PESICalculatorPopup}
        />
        <Route
          path={'/applications-and-calculators/calculators/score'}
          component={SCORECalculatorPopup}
        />

        <Route
          exact
          path="/doctor-and-visitor"
          component={DoctorAndVisitorPage}
        />

        <Route
          exact
          path="/doctor-and-visitor/:slug"
          component={DoctorAndVisitorSinglePage}
        />

        <Route
          exact
          path="/doctor-and-visitor-video/:slug"
          component={DoctorAndVisitorVideoSinglePage}
        />

        <Route exact path="/korisni-posilannya" component={UsefulLinksPage} />

        <Route exact path="/events-calendar" component={EventsCalendarPage} />

        <Route path="/profile" component={ProfileLayout} />

        <Route path="/policies/privacy" component={PrivacyPolicyPage} />

        <Route path="/policies/cookie" component={CookiePolicyPage} />

        <Route component={NotFoundPage} />

        <Redirect to="/" />
      </Switch>
      {/* )} */}

      <Route
        exact
        path={'/login*'}
        render={() => (
          <ModalLayout isLoginAndRegistration>
            <LoginPage activeTab={0} />
          </ModalLayout>
        )}
      />

      <Route
        exact
        path={'/registration'}
        render={() => (
          <ModalLayout isLoginAndRegistration closeModalPopup={success}>
            <LoginPage
              activeTab={1}
              closePopupMainComponent={closeModalWindow}
            />
          </ModalLayout>
        )}
      />

      <Route
        path={'/reset-password'}
        render={() => (
          <ModalLayout isResetPass>
            <ResetPasswordByEmailPage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/reset-password-by-email'}
        render={() => (
          <ModalLayout isResetPass>
            <ResetPasswordByEmailPage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/reset-password-by-phone'}
        render={() => (
          <ModalLayout>
            <ResetPasswordByPhonePage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/restore-password-by-sms-code'}
        render={() => (
          <ModalLayout isLoginAndRegistration>
            <VerificationRestorePasswordBySmsPage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/verification-mobile-phone'}
        render={() => (
          <ModalLayout isLoginAndRegistration>
            <VerificationRestorePasswordBySmsPage />
          </ModalLayout>
        )}
      />

      <Route
        path={'/restore-password'}
        render={() => (
          <ModalLayout isLoginAndRegistration>
            <RestorePasswordPage />
          </ModalLayout>
        )}
      />

      {/*{background && (*/}
      {/*  <Route*/}
      {/*    path={'/restore-password'}*/}
      {/*    render={() => (*/}
      {/*      <ModalLayout>*/}
      {/*        <RestorePasswordPage />*/}
      {/*      </ModalLayout>*/}
      {/*    )}*/}
      {/*  />*/}
      {/*)}*/}
    </AppLayout>
  );
}

export default withErrorHandler(App, $apiClient);
